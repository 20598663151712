<template>
  <div>
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px">
          <div class="flex space-x-2 inner">
            <div class="mt-2">통화일</div>
            <DxDateBox
              :edit-enabled="false"
              :styling-mode="stylingMode"
              width="110"
              height="30"
              v-model="searchType.customTypes.dayStart"
              type="date"
              dateSerializationFormat="yyyyMMdd"
              display-format="yyyy.MM.dd"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              :max="searchType.customTypes.dayEnd"
              maxLength="10"
              invalid-date-message="입력 데이터가 유효하지 않습니다."
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="통화일은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <div class="mt-1">~</div>
            <DxDateBox
              :styling-mode="stylingMode"
              width="110"
              height="30"
              v-model="searchType.customTypes.dayEnd"
              type="date"
              dateSerializationFormat="yyyyMMdd"
              display-format="yyyy.MM.dd"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              maxLength="10"
              :min="searchType.customTypes.dayStart"
              invalid-date-message="입력 데이터가 유효하지 않습니다."
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="통화일은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
          </div>
        </div>
      </div>

      <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
    </div>
  </div>
</template>

<script>
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import validationEngine from 'devextreme/ui/validation_engine';
  import {formatDate, getPastFromToday, isSuccess} from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxDateBox,
      DxButton,

      DxRequiredRule,
      DxValidator,
    },
    props: {},
    watch: {},
    data() {
      return {
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: getPastFromToday(11, 'months'),
            dayEnd: getPastFromToday(0, 'days'),
          },
          paramsData: null,
        },
        codes: {
          recBwCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        dataGrid: {
          keyExpr: 'id',
          refName: 'personalBwCall',
          showMenuColumns: true, //컬럼 표시
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색
          hoverStateEnabled: true, //마우스커서 행 강조
          dataSource: [],
          showActionButtons: {},
          customEvent: {
            cellHoverChanged: true,
            cellPrepared: false,
          },
          callApi: 'CALL_EWM_API',
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          scrolling: {
            // 미사용시 주석처리
            mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
          },
          remoteOperations: {
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'No',
              dataField: 'bwCallOrd',
              alignment: 'center',
              width: 40,
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
            },
            {
              caption: '우수콜구분',
              dataField: 'recBwCd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
              allowSorting: true,
              calculateSortValue: data => this.$_enums.ewm.evalBwCall.values.find(e => data.recBwCd == e.value).label,
              lookup: {
                dataSource: this.$_enums.ewm.evalBwCall.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '콜 구분',
              dataField: 'recTypeCd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
              allowSorting: true,
              calculateSortValue: data => this.$_enums.ewm.evalCallYype.values.find(e => data.recTypeCd == e.value).label,
              lookup: {
                dataSource: this.$_enums.ewm.evalCallYype.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: 'CALLID',
              dataField: 'callId',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowSorting: true,
            },
            {
              caption: '통화일',
              dataField: 'recDate',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowSorting: true,
            },
            {
              caption: '통화시작시간',
              dataField: 'recStartTime',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              calculateCellValue: rowData => {
                return `${this.formatTime(rowData.recStartTime)}`;
              },
            },
            {
              caption: '통화종료시간',
              dataField: 'recEndTime',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              calculateCellValue: rowData => {
                return `${this.formatTime(rowData.recEndTime)}`;
              },
            },
            {
              caption: '통화시간',
              dataField: 'recDuration',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowSorting: true,
            },
            {
              caption: '청취',
              dataField: 'listenCall',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowSorting: false,
              width: 80,
              cellTemplate: (container, options) => {
                let Button = new DxButton({
                  propsData: {
                    template: '<span class="mdi mdi-volume-high"></span>',
                    elementAttr: { class: 'white light_filled txt_S' },
                    value: options.data,
                    onClick: () => {
                      vm.onListenCall(options.data);
                    },
                  },
                });
                Button.$mount();
                container.append(Button.$el);
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description : 청취 */
      onListenCall() {
        this.$_Confirm('선택한 우수콜을 들으시겠습니까?');
      },
      onChangeSearchDay() {
        const regDt = this.searchType.customTypes.dayStart + '000000' + '~' + this.searchType.customTypes.dayEnd + '235959';
        this.searchType.paramsData = { ...this.searchType.paramsData, regDt };

        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.surDt) {
          return formatDate(rowData.surDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      formatTime(timeString) {
        const hours = timeString.substring(0, 2);
        const minutes = timeString.substring(2, 4);
        const seconds = timeString.substring(4, 6);
        return `${hours}:${minutes}:${seconds}`;
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList(sort = '-id') {
        if (!validationEngine.validateGroup('validationSearch').isValid) {
          return;
        }

        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);

            vm.searchType.paramsData['frScheYmd'] = vm.searchType.customTypes.dayStart;
            vm.searchType.paramsData['toScheYmd'] = vm.searchType.customTypes.dayEnd;

            params = { ...params, ...vm.searchType.paramsData };

            const payload = {
              actionname: 'EWM_PERSONAL_BW_CALL',
              data: params,
              loading: false,
              useErrorPopup: true,
            };
            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              let data = res.data.data;
              data.forEach((d, index) => {
                d.bwCallOrd = index + 1;
              });
              const rtnData = {
                data: data,
                totalCount: res.data.header.totalCount,
              };
              vm.$refs.personalBwCall.totalCount = rtnData.totalCount;
              return rtnData;
            } else {
              return () => {
                throw 'Data Loading Error';
              };
            }
          },
        });
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        vm = this;
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.searchType.paramsData = null;
        this.onChangeSearchDay();
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>

<style scoped>
  .sub_new_style01 .page_search_box .inner div {
    display: inline-block;
  }
  .sub_new_style01 .page_search_box .inner > div {
    vertical-align: middle;
    margin-right: 10px;
  }
  .page_search_box .inner .box-btn-search {
    margin-right: 15px;
  }
</style>
